
.report-content {
    padding: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .customTable {
        width: 100%;
        flex: 1;
        margin-top: 16px;
    }

    .filter-box {
        border-bottom: 1px solid #F6F9FF;
        padding-bottom: 16px;
        .question-class {
            display: flex;

            .question-item {
                display: flex;
                align-items: center;

                &:last-child {
                    padding-left: 48px;
                }

                .w300-cascader {
                    width: 300px;
                }

                .W200-select {
                    width: 200px;
                }

                .text {
                    padding-right: 10px;
                }
            }
        }
    }
}
